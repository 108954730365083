<script lang='ts'>
  import { onDestroy } from 'svelte'
  import { navigate } from 'svelte-routing'

  import { ALLOW_ARCH_DEL_EDIT_ROLE, ALLOW_ARCH_DEL_ROLE, MAX_BREADCRUMB_SLUG_LENGTH } from '@/config/constants'

  import { getCookie } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { clickOnTopicReplyFlag, getUserObject, isAllowed, makeTopic, reorderLanguages } from '@/helpers/forumHelpers'
  import { _ } from '@/helpers/i18n'
  import { goToPseudoHash, slugify, toInt, truncate } from '@/helpers/mixHelpers'

  import Flag from '@/components/forum/Flag.svelte'
  import FlagsList from '@/components/forum/FlagsList.svelte'
  import LanguagesFilter from '@/components/forum/LanguagesFilter.svelte'
  import LanguageSuggestion from '@/components/forum/LanguageSuggestion.svelte'
  import ReplyKebab from '@/components/forum/ReplyKebab.svelte'
  import RevisionPopup from '@/components/forum/RevisionPopup.svelte'
  import TranslatedBy from '@/components/forum/TranslatedBy.svelte'
  import BookmarkIcon from '@/components/icons/BookmarkIcon.svelte'
  import IconLike from '@/components/icons/IconLike.svelte'
  import IconTopic from '@/components/icons/IconTopic.svelte'
  import ShareButtons from '@/components/share/ShareButtons.svelte'
  import UserAvatar from '@/components/social/UserAvatar.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import Kebab from '@/components/ui/Kebab.svelte'
  import KebabItem from '@/components/ui/KebabItem.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'
  import ReportButton from '@/components/ui/ReportButton.svelte'

  import {
    Bread,
    CurrentCategory,
    LanguageGroups,
    QuillOptions,
    QuillType,
    QuillWrapper,
    RequestParams,
    Topic,
    TopicData,
    TopicReplay,
    TranslateItem
  } from '@/definitions/langoid'
  import { forumConfig, userStorredConfig } from '@/store'

  let quill: QuillType
  export let iso = '',
    page = 1,
    topicId = 0

  const options2: QuillOptions = {},
    replyToReplyOptions: QuillOptions = {}
  const rtes: any = {}

  let breads: Bread[] = []
  let content3 = { html: '', text: '' }

  let replies: Record<number, TopicReplay[]> = {},
    translations: Record<number, string> = {},
    currentCategory: CurrentCategory = {} as CurrentCategory,
    topic: Topic = {} as Topic,
    myVotes: Record<string, number> = {},
    allowedTo = {
      read: false,
      write: false
    },
    isTopicTranslation: boolean,
    myLanguages: number[] = [],
    categoryTranslations: TopicData['categoryTranslations'] = [],
    showNativeAlert = true,
    showForEng = false,
    element: any = {},
    showMainReply = false

  $: if (element) {
    replies = replies
  }

  let user = $userStorredConfig
  let replyLanguage = user.id_lang_interface
  const selectReplyLanguage = (languageId: number): void => {
    replyLanguage = languageId
    showNativeAlert = $userStorredConfig.id_lang_native === replyLanguage
    showForEng = $userStorredConfig.id_lang_learning !== 1 && replyLanguage === 1
  }

  userStorredConfig.subscribe((value) => {
    if (Object.keys(value).length) {
      user = value
      replyLanguage = user.id_lang_interface
    }
  })

  $: isTopicTranslation = topic.original_language !== topic.id_language

  const loadData = (data: TopicData): void => {
    goToPseudoHash('reply')
    replies = data.replies
    topic = data.topic
    myVotes = data.myVotes
    translations = data.translations
    categoryTranslations = data.categoryTranslations as TopicData['categoryTranslations']
    currentCategory = data.category

    const categoryUrl = `/${iso}/c/${currentCategory.title}/${currentCategory.id}`
    breads = [
      { mobileUrl: categoryUrl, text: '', url: `/${iso}` },
      { text: $_('forum.categories'), url: `/${iso}/forum/categories` },
      { text: currentCategory.title, url: categoryUrl },
      { text: truncate(topic.content, MAX_BREADCRUMB_SLUG_LENGTH) }
    ]

    const permissions = isAllowed(topic, user)
    allowedTo = {
      read: permissions[0],
      write: permissions[1]
    }
    import('@/helpers/svelteQuill').then((el: QuillWrapper) => {
      quill = el.quill
    })
  }

  const jwt = getCookie('jwt')
  let unsubscribe: () => void
  if (jwt) {
    unsubscribe = forumConfig.subscribe((config: LanguageGroups) => {
      if (config.known.length) {
        fetchData('forum/loadTopic', { config, iso, page, topicId }).then((data) => {
          loadData(data)
          myLanguages = config.known.map(el => toInt(el.lang))
          if (myLanguages[0]) {
            currentCategory.title = categoryTranslations?.[myLanguages[0]]?.title || currentCategory.title
            currentCategory.content = categoryTranslations?.[myLanguages[0]]?.content || currentCategory.content
          }
        })
      }
    })
  } else {
    fetchData('forum/loadTopic', { config: {}, iso, page, topicId }).then((data) => {
      loadData(data)
    })
  }

  const isReplyValid = (params: RequestParams): boolean => {
    if (params.content.length < 1) {
      alert($_('forum.emptyReply'))
      return false
    }
    return true
  }

  const addReply = async (replyId: number, e: SubmitEvent) => {
    e.preventDefault()
    const content = replyId === 0 ? content3.html : rtes[replyId].html
    const params = {
      content,
      language: replyLanguage,
      replyId,
      topicId: topic.id
    }
    if (!isReplyValid(params)) {
      return
    }
    const data = await fetchData('forum/addReply', params)
    replies[replyId] = replies[replyId] ? [...Object.values(replies[replyId]), data.reply] : [data.reply]
    const target = e.target as HTMLFormElement
    if (target) {
      target.reset()
      target.getElementsByClassName('ql-editor')[0].innerHTML = ''
    }
    if (replyId === 0) showMainReply = false
    else cancelReplyToReply(replyId)
  }

  const cancelReplyToReply = (replyId: number) => {
    const replyIdElement = document.getElementById('formReply' + replyId)
    const replyButton: HTMLElement | null = document.querySelector('.primary.-id' + replyId)
    if (replyIdElement) {
      replyIdElement.style.display = 'none'
    }
    if (replyButton) {
      replyButton.style.display = 'block'
    }
  }

  const vote = async (topicId: number, replyId: number, vote: any, reason = '', targetElement: any = undefined) => {
    if (!targetElement) {
      return
    }
    targetElement.classList.add('my')
    const data: any = await fetchData('forum/vote', { reason, replyId, topicId, vote })
    myVotes[topicId + ':' + replyId] = data.rating
    if (data.rating === 0) {
      if (targetElement) {
        targetElement.classList.remove('my')
      }
    }
  }

  const mark = (replyId: number): boolean => myVotes?.[topic.id + ':' + replyId] > 0
  const markDownvote = (replyId: number): boolean => myVotes?.[topic.id + ':' + replyId] < 0
  const languagesArray = (reply: TopicReplay) => {
    const exists = translations?.[reply.id]
    if (exists) {
      const ret = exists.toString()
      return ret.split(',').map(el => +el)
    }

    return []
  }
  const clickOnFlag = async (language: number, topicId: number, original = false) => {
    const data: TranslateItem = await fetchData('forum/translateSingleItem', { language, original, replyId: 0, topicId })
    topic.content = data.content
    topic.id_language = data.id_language
    topic.translatedBy = {
      avatar: data.avatar,
      id: data.id_user,
      level: data.level,
      nick: data.nick
    }
    topic.revision_count = data.revision_count
    topic.revision_details = data.revision_details

    if (language) {
      currentCategory.title = categoryTranslations?.[language]?.title || currentCategory.title
      currentCategory.content = categoryTranslations?.[language]?.content || currentCategory.content
    }
  }
  const clickOnReplyFlag = async (language: number, topicId: number, replyId: number, parentId = 0, original = false) => {
    replies = await clickOnTopicReplyFlag(replies, language, topicId, replyId, parentId, original)
  }

  const setContent2 = (e: CustomEvent, replyId: number) => {
    rtes[replyId] = e.detail
  }

  const addRemoveBookmark = (content: any, type: string) => {
    let bookmarked = false
    if (type === 'topic') {
      element = content
      bookmarked = !element.bookmarked
      topic.bookmarked = bookmarked
    } else {
      element = Object.values(replies).flatMap(items => items).find(item => item.id === content.id)
      if (element) {
        bookmarked = !element.bookmarked
        element.bookmarked = bookmarked
      }
    }
    fetchData('forum/addRemoveBookmark', { bookmarked, contentId: element.id, type })
  }
  const setContent3 = (e: CustomEvent) => {
    content3 = e.detail
  }

  const createLink = (idTopic: number, idReply: number) => {
    return `/forum-translate/${idTopic}` + (idReply ? `/${idReply}` : '')
  }

  onDestroy(() => {if (unsubscribe) unsubscribe()})
</script>
<Breadcrumb {breads} filters>
  <h2>{truncate(topic.content, MAX_BREADCRUMB_SLUG_LENGTH)}</h2>
  <div slot='filters' class='_breadcrumbicons'>
    <LanguagesFilter />
  </div>
</Breadcrumb>

{#if topic.id}
  {#if allowedTo.read}
    <div class='forum-page replies-list'>
      <div class='single-reply _gap24 -topic'>
        <div class='single-reply-content _vertical-small'>
          <div class='top-section'>
            <div class='meta-area _row'>
              <UserAvatar date={topic.created_at} dateVariant='withDate' metaDataVariant='right' user={getUserObject(topic)} />
              •
              <div class='repliesNumber'>
                {replies?.[0]?.length || 0} {replies?.[0]?.length === 1 ? 'reply' : 'replies'}
              </div>
            </div>
            <div class='reply-actions'>
              <div class='translate-options'>
                {#if topic?.original_language}
                  <Flag
                    id={topic.original_language}
                    active={topic.id_language === topic.original_language}
                    clickOnFlag={(lang) => { clickOnFlag(lang, topic.id, true) }}
                  />
                {/if}
                {#if topic.languages?.length} |{/if}
                <FlagsList
                  active={topic.id_language}
                  clickOnFlag={(lang) => { clickOnFlag(lang, topic.id) }}
                  languages={reorderLanguages(topic.languages, myLanguages)}
                />
              </div>
              {#if user.level > 0}
                <BookmarkIcon bookmarked={topic.bookmarked} onClick={() => addRemoveBookmark(topic, 'topic')} />
                <IconLike liked={mark(0)} onClick={(e) => vote(topic.id, 0, 'like', '', e.target || undefined)} />
              {/if}
              <ShareButtons url='https://langoid.com/{iso}/t/{slugify(topic.content)}/{topic.id}' />
              <Kebab>
                <ReportButton
                  category='forumTopic'
                  kebabItem
                  rel={topic.id}
                  selected={markDownvote(0)}
                  on:report={(e) => {
                  vote(topic.id, 0, e.detail?.type, e.detail?.description, undefined)
                  }}
                />
                <hr />
                {#if user.is_admin >= ALLOW_ARCH_DEL_ROLE}
                  {#if topic.is_locked}
                    <KebabItem
                      action={() => { makeTopic('unlocked', topic.id); topic.is_locked = 0 }}
                      icon='LockSimple'
                      label={$_('forum.unlock')}
                    />
                    <hr />
                  {:else}
                    <KebabItem
                      action={() => { makeTopic('locked', topic.id); topic.is_locked = 1 }}
                      icon='LockSimpleOpen'
                      label={$_('forum.lock')}
                    />
                    <hr />
                  {/if}
                  {#if topic.is_archived}
                    <KebabItem
                      action={() => { makeTopic('unarchived', topic.id); topic.is_archived = 0 }}
                      icon='FolderMinus'
                      label={$_('forum.unarchive')}
                    />
                    <hr />
                  {:else}
                    <KebabItem
                      action={() => { makeTopic('archived', topic.id); topic.is_archived = 1 }}
                      icon='Folder'
                      label={$_('forum.archive')}
                    />
                    <hr />
                  {/if}
                  <RevisionPopup
                    revision={topic}
                    type={isTopicTranslation ? 'topic_translation' : 'topic'}
                  />
                {/if}
                {#if user.is_admin >= ALLOW_ARCH_DEL_ROLE || (user.id === topic.id_user && !isTopicTranslation) ||
                (topic.translatedBy?.id === topic.id_user && isTopicTranslation)}
                  <KebabItem
                    action={() => makeTopic('deleted', topic.id, isTopicTranslation ? topic.id_language : 0)}
                    icon='Trash'
                    label={$_('forum.delete')}
                  />
                  <hr />
                {/if}
                {#if (user.id === topic.id_user && !isTopicTranslation) ||
                (topic.translatedBy?.id === user.id && isTopicTranslation) || user.is_admin >= ALLOW_ARCH_DEL_EDIT_ROLE}
                  <KebabItem
                    href={'/forum-edit/' + topic.id + (isTopicTranslation ? `/${topic.id_language}` : '')}
                    icon='PencilSimple'
                    label={$_('forum.edit')}
                  />
                {/if}
              </Kebab>
            </div>
          </div>

          <div class='topic-title-with-flag'>
            <p>
              <IconTopic type={topic.type} /> {topic.content}</p>
            {#if topic.target_language}
              <Flag id={topic.target_language} clickOnFlag={() => { }} />
            {/if}
          </div>
          <div class='reply-or-translate'>
            <div class='bottom-actions'>
              <input
                class='primary'
                class:-hidden={showMainReply === true}
                type='button'
                value={$_('forum.addReply')}
                on:click={() => { showMainReply = true }}
              />
              <button
                class='_secondary-btn'
                type='button'
                on:click={() => navigate(createLink(topic.id, 0))}
              >{$_('forum.addTranslation')}
              </button>
            </div>
            {#if isTopicTranslation}
              <TranslatedBy user={topic.translatedBy} />
            {/if}
          </div>
        </div>
        {#if showMainReply}
          <hr />
        {/if}
        {#if user.id && allowedTo.write && showMainReply}
          <form class='your-reply-form _vertical-small' action='' on:submit={(e) => {addReply(0,e)}}>
            <div class='choose-language-reply'>
              <div class='select-menu'>
                <div class='replyLanguageText'>{$_('forum.replyLanguage')}</div>
                <LanguageSelect onChange={selectReplyLanguage} selectedLanguage={replyLanguage} />
              </div>
              <LanguageSuggestion {showForEng} {showNativeAlert} />
            </div>
            <div class='reply-form-content'>
              {#if quill}
                <div class='editor' on:text-change={setContent3} use:quill={replyToReplyOptions} />
              {/if}
            </div>
            <div class='submit-form'>
              <input type='submit' value={$_('forum.postReply')} />
              <input type='button' value={$_('mix.cancel')} on:click={() => {showMainReply = false}} />
            </div>
          </form>
        {/if}
      </div>
      {#if replies[0]?.length}
        {#each replies[0] as reply, index(reply.id)}
          {@const isReplyTranslation = reply.original_language !== reply.id_language}
          <div
            class='single-reply-wrapper -reply'
            class:-lastRootReply={index === replies[0]?.length - 1}
          >
            <div class='rectangle' />
            <div id='reply-{reply.id}' class='single-reply _gap24 -reply'>
              <div class='single-reply-content'>
                <div class='top-section'>
                  <div class='meta-area _row'>
                    <UserAvatar date={reply.created_at} dateVariant='withDate' metaDataVariant='right' user={getUserObject(reply)} />
                    {#if replies[reply.id]}
                      •
                      <div class='repliesNumber'>
                        {replies[reply.id].length} {replies[reply.id].length === 1 ? 'reply' : 'replies'}
                      </div>
                    {/if}
                  </div>
                  <div class='reply-actions'>
                    <div class='translate-options'>
                      {#if reply.original_language || reply.id_language}
                        <Flag
                          id={reply.original_language || reply.id_language}
                          active={reply.id_language === reply.original_language}
                          clickOnFlag={(lang) => { clickOnReplyFlag(lang, topic.id, reply.id, 0, true) }}
                        />
                      {/if}
                      {#if languagesArray(reply)?.length} |{/if}
                      <FlagsList
                        active={reply.id_language}
                        clickOnFlag={(lang) => { clickOnReplyFlag(lang, topic.id, reply.id) }}
                        languages={reorderLanguages(languagesArray(reply), myLanguages)}
                      />
                    </div>
                    {#if user.level > 0}
                      <BookmarkIcon bookmarked={reply.bookmarked} onClick={() => addRemoveBookmark(reply, 'reply')} />
                      <IconLike liked={mark(reply.id)} onClick={(e) => vote(topic.id, reply.id, 'like', '', e.target || undefined)} />
                    {/if}
                    <ShareButtons url='https://langoid.com/{iso}/t/{slugify(topic.content)}/{topic.id}/?reply={reply.id}' />
                    <ReplyKebab {isReplyTranslation} {markDownvote} {reply} {topic} {vote} />
                  </div>
                </div>
                <div class='content ql-editor'>
                  {@html reply.content}
                </div>
                <div class='reply-or-translate'>
                  <div class='bottom-actions'>
                    <button
                      class='primary -id{reply.id}'
                      type='button'
                      on:click={() => {
                  const formReply = document.getElementById('formReply' + reply.id)
                  const replyButton = document.querySelector('.primary.-id' + reply.id)
                  if (formReply) {
                    formReply.style.display = 'flex'
                    formReply.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
                  }
                  if  (replyButton) {
                    replyButton.style.display = 'none'
                  }
                }}
                    >
                      {$_('forum.reply')}</button>

                    <button
                      class='_secondary-btn'
                      type='button'
                      on:click={() => navigate(createLink(topic.id, reply.id))}
                    >{$_('forum.addTranslation')}
                    </button>
                  </div>
                  {#if isReplyTranslation}
                    <TranslatedBy user={reply.translatedBy} />
                  {/if}
                </div>
              </div>
              <form id='formReply{reply.id}' class='reply-form _vertical-small' action='' on:submit={(e) => { addReply(reply.id, e) }}>
                <hr />
                {#if user?.id}
                  <div class='choose-language-reply'>
                    <div class='select-menu'>
                      <div class='replyLanguageText'>{$_('forum.replyLanguage')}</div>
                      <LanguageSelect onChange={selectReplyLanguage} selectedLanguage={replyLanguage} />
                    </div>
                    <LanguageSuggestion {showForEng} {showNativeAlert} />
                  </div>
                  <div class='reply-form-content'>
                    {#if quill}
                      <div class='editor' on:text-change={(e) => setContent2(e, reply.id)} use:quill={options2} />
                    {/if}
                  </div>
                  <div class='submit-form'>
                    <input class='submitForm' type='submit' value={$_('forum.postReply')} />
                    <input type='button' value={$_('mix.cancel')} on:click={() => {cancelReplyToReply(reply.id)}} />
                  </div>
                {:else}
                  <p>{$_('forum.mustBeLogged')}</p>
                  <a href='/login'>Login</a>
                {/if}
              </form>
            </div>
          </div>
          {#if replies[reply.id]?.length}
            {#each replies[reply.id] as innerReply, index(innerReply.id)}
              {@const isInnerReplyTranslation = innerReply.original_language !== innerReply.id_language}
              <div
                class='single-reply-wrapper -innerReply'
                class:-lastInGroup={index === replies[reply.id]?.length - 1}
              >
                <div class='leftBorder' />
                <div class='rectangle' />
                <div id='reply-{innerReply.id}' class='single-reply _gap24 -innerReply'>
                  <div class='top-section'>
                    <div class='meta-area _row'>
                      <UserAvatar
                        date={innerReply.created_at}
                        dateVariant='withDate'
                        metaDataVariant='right'
                        user={getUserObject(innerReply)}
                      />
                    </div>
                    <div class='reply-actions'>
                      <div class='translate-options'>
                        {#if innerReply.original_language || innerReply.id_language}
                          <Flag
                            id={innerReply.original_language || innerReply.id_language}
                            active={innerReply.id_language === innerReply.original_language}
                            clickOnFlag={(lang) => { clickOnReplyFlag(lang, topic.id, innerReply.id, reply.id, true) }}
                            variant='small'
                          />
                        {/if}
                        {#if languagesArray(innerReply)?.length} |{/if}
                        <FlagsList
                          active={innerReply.id_language}
                          clickOnFlag={(lang) => { clickOnReplyFlag(lang, topic.id, innerReply.id, reply.id) }}
                          languages={reorderLanguages(languagesArray(innerReply), myLanguages)}
                        />
                      </div>
                      {#if user.level > 0}
                        <BookmarkIcon bookmarked={innerReply.bookmarked} onClick={() => addRemoveBookmark(innerReply, 'reply')} />
                        <IconLike liked={mark(innerReply.id)} onClick={(e) => vote(topic.id, innerReply.id, 'like', '', e.target || undefined)} />
                      {/if}
                      <ShareButtons url='https://langoid.com/{iso}/t/{slugify(topic.content)}/{topic.id}?reply={innerReply.id}' />
                      <ReplyKebab isReplyTranslation={isInnerReplyTranslation} {markDownvote} reply={innerReply} {topic} {vote} />
                    </div>
                  </div>
                  <div class='content ql-editor'>
                    {@html innerReply.content}
                  </div>
                  <div class='reply-or-translate'>
                    <div class='bottom-actions'>
                      <button
                        class='_secondary-btn'
                        type='button'
                        on:click={() => navigate(createLink(topic.id, innerReply.id))}
                      >{$_('forum.addTranslation')}
                      </button>
                    </div>
                    {#if isInnerReplyTranslation}
                      <TranslatedBy user={innerReply.translatedBy} />
                    {/if}
                  </div>
                </div>
              </div>
            {/each}
          {/if}
        {/each}
      {:else if allowedTo.write}
        <h2>{$_('forum.noRepliesYet')}</h2>
      {/if}
    </div>
  {:else}
    <h2>{$_('forum.notAllowed')}</h2>
    <p>{$_('forum.requiredLevel')} {topic.read_level}</p>
    {#if user.is_admin > 0}
      <p>{$_('forum.requiredAdminLevel')} {topic.admin_read_level}</p>
    {/if}
  {/if}
{:else}
  {$_('forum.noTopic')}
{/if}

<style lang='scss'>
  .ql-editor {
    min-height: 0;
    padding: 1.2rem 0;

    > :global(p) {
      line-height: 1.5;
    }
  }

  .topics-page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .replies-list > h2 {
    padding: 2.4rem 0;
    text-align: center;
    box-shadow: none;
  }

  .single-reply-wrapper {
    display: flex;
    align-items: stretch;
    border-left: 0.1rem solid var(--Gray-Medium);

    &.-reply {
      margin-left: 2.4rem;

      &:nth-last-child(1) {
        border-left: none;

        > .rectangle {
          border-left: 0.1rem solid var(--Gray-Medium);
        }
      }
    }

    &.-innerReply {
      margin-left: 2.4rem;
      padding-left: 4.8rem;
    }

    > .leftBorder {
      border-left: 0.1rem solid var(--Gray-Medium);
    }

    &.-lastInGroup > .leftBorder, > .rectangle {
      min-width: 2.4rem;
      height: 4rem;
      border-bottom: 0.1rem solid var(--Gray-Medium);
      border-radius: 0 0 0 1.6rem;
    }

    &.-lastInGroup > .rectangle {
      display: none
    }

    &.-lastRootReply {
      border-left: solid transparent 0.1rem;

      > .rectangle {
        border-left: 0.1rem solid var(--Gray-Medium);
      }

      ~ .single-reply-wrapper {
        border-left: solid transparent 0.1rem;
      }
    }
  }

  .single-reply {
    &.-reply, &.-innerReply {
      width: 100%;
      margin-top: 1.6rem;
    }

    > .content {
      font: var(--Regular-400);
      color: var(--text-primary-color);
    }
  }

  .top-section {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
    justify-content: space-between;
    width: 100%;
  }

  .meta-area {
    > :global(.user-preview) {
      justify-content: start;
    }

    > :global(.user-preview .avatar-wrapper) {
      scale: 0.8;
    }

    > .repliesNumber {
      font: var(--Regular-200);
      color: var(--text-primary-color);
    }
  }

  .reply-actions {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
  }

  .translate-options {
    display: flex;
    gap: 0.4rem;
    min-width: 4.8rem;
  }

  .topic-title-with-flag {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    font: var(--Semibold-600);
    color: var(--text-secondary-color);

    > p {
      line-height: 1.5;
    }
  }

  .reply-or-translate {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .bottom-actions {
    display: flex;
    gap: 0.8rem;
    width: 100%;

    > .primary {
      border: 0.2rem solid var(--Primary-Medium);

      &.-hidden {
        display: none;
      }
    }
  }

  .your-reply-form, .reply-form {
    margin: 0;
  }

  .submit-form {
    display: flex;
    gap: 0.8rem;

    > input {
      width: fit-content;
      padding: 0.8rem 2.4rem;
    }
  }

  .reply-form {
    display: none;
  }

  .choose-language-reply {
    display: flex;
    gap: 2.4rem;
    align-items: end;
  }

  .select-menu {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    width: 60%;

    > .replyLanguageText {
      font: var(--Medium-300);
    }
  }

  .reply-form-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--color-white);
  }

  .single-reply-content > .content {
    height: auto;
  }

  @media (max-width: 768px) {
    .replies-list {
      width: fit-content;
    }

    .single-reply-content {
      padding: 0;
    }

    .topics-page-header {
      padding: 0.8rem 1.6rem;
      background-color: var(--Base-White);
      box-shadow: var(--Shadow-Z);
    }

    .single-reply {
      padding: 0;
      background-color: inherit;

      &.-topic {
        margin-bottom: 1.6rem;
        border: none;
      }

      &.-reply, &.-innerReply {
        margin-top: 0;
        padding: 1.6rem 0 0 0;
        border: none;
      }
    }

    .reply-form {
      margin-right: 1.6rem;
    }

    .reply-or-translate {
      flex-direction: column-reverse;
      gap: 1.6rem;
    }

    .bottom-actions {
      padding-top: 0.8rem;
    }

    .single-reply-wrapper {
      &.-reply {
        margin: 0;
        padding-bottom: 1.6rem;
      }

      &.-innerReply {
        margin: 0;
        padding-left: 2.4rem;
      }

      > .rectangle {
        height: 3rem;
      }
    }

    .choose-language-reply {
      flex-direction: column-reverse;
      align-items: start;
    }

    .select-menu {
      width: 100%;
    }
  }
</style>
