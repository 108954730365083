<script lang='ts'>
  import { navigate } from 'svelte-routing'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { emojiAutocomplete } from '@/helpers/emojiHelpers'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { mapTranslations } from '@/helpers/forumHelpers'
  import { range, slugify, toInt } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import LanguageSuggestion from '@/components/forum/LanguageSuggestion.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  import { UserData } from '@/definitions/langoid'
  import { _ } from '@/libs/i18n'
  import { userStorredConfig } from '@/store'

  export let formShown = false,
    myTranslations: any = {},
    currentCategory: any = {},
    topics: any = [],
    user: UserData,
    allCategoriesList: { id: number, title: string }[]

  let showNativeAlert = false,
    showForEng = false,
    content: string,
    showAdvancedOptions = false

  let settings: any = {
    locked: false,
    targetLanguage: '',
    topicLanguage: '',
    type: 'normal'
  }// save this in localStorage

  const MIN_REQUIRED_TOPIC_LENGTH = 2
  const MAX_CHARACTERS_NUMBER = 250

  const addTopic = async (e: SubmitEvent) => {
    if (content.length < MIN_REQUIRED_TOPIC_LENGTH) {
      notifier.error($_('forum.mustWriteContent'))
      return
    }
    if (!(toInt(settings.topicLanguage) > 0)) {
      notifier.error($_('forum.mustSelectLanguage'))
      return
    }
    const data = await fetchData('forum/addTopic', { categoryId: currentCategory.id, content, settings })
    settings = {
      locked: false,
      targetLanguage: '',
      topicLanguage: '',
      type: 'normal'
    }
    topics.push(data.topic)
    topics = topics.map((el: any) => mapTranslations(el, myTranslations))
    const targetEvent = e.target as HTMLFormElement
    targetEvent.reset()
    formShown = false
    navigate(`/${getIsoFromId(data.topic.id_language)}/t/${slugify(data.topic.content)}/${data.topic.id}`)
    notifier.success($_('forum.topicAdded'))
  }

  const selectTopicLanguage = (languageId: number): void => {
    settings.topicLanguage = languageId
    settings = { ...settings }
    showNativeAlert = $userStorredConfig.id_lang_native === settings.topicLanguage
    showForEng = $userStorredConfig.id_lang_learning !== 1 && settings.topicLanguage === 1
  }

  const selectTargetLanguage = (languageId: number): void => {
    settings.targetLanguage = languageId
    settings = { ...settings }
  }
</script>
<form class='add-topic-form _gap24' class:-hidden={!formShown} action='' on:submit|preventDefault={addTopic}>
  <p>
    <label class='topicTitleHeader' for='content'>{$_('forum.topicTitle')}</label>
    <textarea
      id='content'
      maxlength='250'
      placeholder={$_('mix.writeHere')}
      required
      bind:value={content}
      use:emojiAutocomplete
      name='content'
    />
    <span class='counter'>{content ? MAX_CHARACTERS_NUMBER - content.length : ''}</span>
  </p>
  <p>
    <span class='label'>{$_('forum.chooseCategory')}</span>
    <select bind:value={currentCategory.id}>
      {#each allCategoriesList as category}
        <option value={category.id}>{category.title}</option>
      {/each}
    </select>
  </p>
  <p>
    <span class='label'>{$_('forum.topicWrittenIn')}</span>
    <LanguageSelect onChange={selectTopicLanguage} selectedLanguage={toInt(settings.topicLanguage)} />
    <LanguageSuggestion {showForEng} {showNativeAlert} />
  </p>
  <p>
    <span class='label'>{$_('forum.languageSpecificTopic')}</span>
    <LanguageSelect onChange={selectTargetLanguage} selectedLanguage={toInt(settings.targetLanguage)} />
  </p>
  {#if user.is_admin > 2 || user.is_premium || user.level > 10}
    {#if !showAdvancedOptions}
      <p><a href='#top' on:click|preventDefault={() => { showAdvancedOptions = true }}>
        {$_('forum.showAdvancedOptions')}</a>
      </p>
    {:else}
      <p><a href='#top' on:click|preventDefault={() => { showAdvancedOptions = false }}>
        {$_('forum.hideAdvancedOptions')}</a>
      </p>
      <p>
        <span class='label'>{$_('forum.topicType')}</span>
        <select bind:value={settings.type}>
          <option value='normal'>{$_('forum.topicTypeNormal')}</option>
          <option value='guide'>{$_('forum.topicTypeGuide')}</option>
        </select>
      </p>
      <p>
        <span class='label'>{$_('forum.topicLocked')}</span>
        <input type='checkbox' value='1' bind:checked={settings.locked} />
      </p>
      {#if user.is_admin}
        <p>
          <span class='label'>{$_('forum.adminReadLevel')}</span>
          <select bind:value={settings.adminReadLevel}>
            {#each range(user.is_admin, 0) as i}
              <option value={i}>{i}</option>
            {/each}
          </select>
        </p>
        <p>
          <span class='label'>{$_('forum.adminWriteLevel')}</span>
          <select bind:value={settings.adminWriteLevel}>
            {#each range(user.is_admin, 0) as i}
              <option value={i}>{i}</option>
            {/each}
          </select>
        </p>
      {/if}
    {/if}
  {/if}
  <div class='add-cancel-topic'>
    <input type='submit' value={$_('forum.addTopic')} />
    <input type='button' value='Cancel' on:click={() => { formShown = false }} />
  </div>
</form>
<style lang='scss'>
  label,
  .label {
    display: block;
    font: var(--Regular-200);
    color: var(--text-primary-color);
  }

  .add-topic-form {
    position: absolute;
    top: 4.8rem;
    right: -1.6rem;
    z-index: 10;
    gap: 1.6rem;
    width: 35rem;
    padding: 0.8rem;
    border-top: 1px solid var(--Gray-Light);
    border-right: 1.2rem;
    border-radius: 1.6rem;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);

    > p {
      width: 100%;

      > .topicTitleHeader {
        margin: 0;
        padding: 0.8rem;
        font: var(--Medium-300);
        color: var(--text-primary-color);
      }

      > textarea {
        width: 100%;
      }
    }

    &.-hidden {
      display: none;
    }
  }

  .add-cancel-topic {
    display: flex;
    gap: 1rem;
  }

  select {
    width: 100%;
  }

  @media(max-width: 768px) {
    .add-topic-form {
      position: fixed;
      top: initial;
      bottom: 0;
      left: 0;
      z-index: 90;
      width: 100%;
      height: 70%;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
</style>
